@import url("https://use.typekit.net/aea3wye.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-dark;
    @apply text-white;
}

.product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr max-content max-content;
}

.ReactModal__Content {
    @apply bg-dark !important;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.mirror {
    --tw-scale-x: -1;
}
